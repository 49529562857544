export default {
  "header": {
    "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CDIL Consulting"])}
  },
  "about": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hacer crecer las empresas a través de la tecnología"])},
    "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>CDIL Consulting</b> es una empresa especializada en la creación de soluciones de software personalizadas para empresas."])},
    "reasons": {
      "a": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experiencia"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tenemos una amplia experiencia en la entrega de soluciones de software de <b>alta calidad a tiempo y dentro del presupuesto.</b>"])}
      },
      "b": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colaboración"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>Trabajamos en estrecha colaboración con nuestros clientes</b> para comprender sus necesidades únicas y desarrollar software que satisfaga esas necesidades."])}
      },
      "c": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servicio"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nos comprometemos a proporcionar <b>servicio al cliente y soporte</b> durante todo el proceso de desarrollo y después de la entrega."])}
      }
    },
    "whychooseus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Por qué elegirnos?"])}
  },
  "contact": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su éxito comienza aquí"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permítanos ayudarle a lograr el éxito en su negocio"])},
    "form": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su nombre"])},
      "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su empresa"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su correo electrónico"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su teléfono"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Cómo podemos ayudarle?"])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trabajemos juntos"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar su solicitud"])}
    },
    "errors": {
      "This field may not be blank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este campo no puede estar vacío"])},
      "Enter a valid email address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduzca una dirección de correo electrónico válida"])}
    },
    "success": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gracias por su mensaje"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nos pondremos en contacto con usted lo antes posible."])}
    }
  },
  "home": {
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trabajemos juntos"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diseñamos, desarrollamos y entregamos software de vanguardia adaptado a sus necesidades."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soluciones de software innovadoras para su empresa"])},
    "whoarewe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Quiénes somos?"])}
  },
  "login": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Área de clientes"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección de correo electrónico"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar sesión"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Aún no tiene una cuenta?"])},
    "registerlink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regístrese aquí"])}
  },
  "menu": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicio"])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Por qué elegirnos?"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacto"])}
  },
  "footer": {
    "allrights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CDIL Consulting - Todos los derechos reservados"])}
  }
}