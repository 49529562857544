export default {
  "header": {
    "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CDIL Consulting"])}
  },
  "about": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Growing businesses through technology"])},
    "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>CDIL Consulting</b> is a company specializing in creating custom software solutions for businesses."])},
    "reasons": {
      "a": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expertise"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have extensive experience in delivering <b>high-quality software solutions on time and within budget.</b>"])}
      },
      "b": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collaboration"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>We work closely with our clients</b> to understand their unique needs and develop software that meets those needs."])}
      },
      "c": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are committed to providing <b>customer service and support</b> throughout the development process and after delivery."])}
      }
    },
    "whychooseus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Why choose us?"])}
  },
  "contact": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your success starts here"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let us help you achieve success in your business"])},
    "form": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your name"])},
      "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your company"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your email"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your phone"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How can we help you?"])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let's work together"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit your request"])}
    },
    "errors": {
      "This field may not be blank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field may not be blank"])},
      "Enter a valid email address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a valid email address"])}
    },
    "success": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for your message"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We will contact you as soon as possible."])}
    }
  },
  "home": {
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let's work together"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We design, develop, and deliver cutting-edge software tailored to your needs."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Innovative software solutions for your business"])},
    "whoarewe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Who are we?"])}
  },
  "login": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client area"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don't have an account yet?"])},
    "registerlink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register here"])}
  },
  "menu": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Why choose us?"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])}
  },
  "footer": {
    "allrights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CDIL Consulting - All rights reserved"])}
  }
}