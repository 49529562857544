export default {
  "header": {
    "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CDIL Consulting"])}
  },
  "about": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faire grandir les entreprises grâce à la technologie"])},
    "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>CDIL Consulting</b> est une entreprise spécialisée dans la création de solutions logicielles personnalisées pour les entreprises."])},
    "reasons": {
      "a": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expertise"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous avons une solide expérience dans la livraison de solutions logicielles de <b>haute qualité dans les délais et dans le respect du budget.</b>"])}
      },
      "b": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collaboration"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>Nous travaillons en étroite collaboration avec nos clients</b> pour comprendre leurs besoins uniques et développer des logiciels qui répondent à ces besoins."])}
      },
      "c": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous nous engageons à fournir un <b>service client et un support</b> tout au long du processus de développement et après la livraison."])}
      }
    },
    "whychooseus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pourquoi nous choisir ?"])}
  },
  "contact": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre succès commence ici"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laissez-nous vous accompagner dans la réussite de votre entreprise"])},
    "form": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre nom"])},
      "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre entreprise"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre e-mail"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre téléphone"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment pouvons-nous vous aider ?"])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Travaillons ensemble"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer votre demande"])}
    },
    "errors": {
      "This field may not be blank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce champ ne peut pas être vide"])},
      "Enter a valid email address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez une adresse e-mail valide"])}
    },
    "success": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merci pour votre message"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous vous contacterons dans les plus brefs délais."])}
    }
  },
  "home": {
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Travaillons ensemble"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous concevons, développons et livrons des logiciels de pointe adaptés à vos besoins."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Des solutions logicielles innovantes pour votre entreprise"])},
    "whoarewe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qui sommes-nous ?"])}
  },
  "login": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espace client"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse e-mail"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se connecter"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas encore de compte ?"])},
    "registerlink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inscrivez-vous ici"])}
  },
  "menu": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accueil"])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pourquoi nous choisir ?"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])}
  },
  "footer": {
    "allrights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CDIL Consulting - Tous droits réservés"])}
  }
}