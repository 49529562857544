import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import i18n from '../i18n'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: i18n.global.t('home.title')
    }
  },
  { 
    path: '/:pathMatch(.*)*', 
    component: HomeView,
    meta: {
      title: i18n.global.t('home.title')
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
      }
    }
  },
  routes
})


const DEFAULT_TITLE = 'CDIL Consulting';
router.afterEach((to, from) => {
  const env = process.env.VUE_APP_ENV;
  document.title = DEFAULT_TITLE + (env !== 'PRD' ? ' - ' + env : '') + (to.meta.title ? ' - ' + to.meta.title : '');
});

export default router
