<template>
  <div class="scroll-indicator">
    <span></span>
  </div>

  <section class="home-introduction" id="home-section">
    <div class="home-introduction-content" v-motion="{ initial: { opacity: 0 }, enter: { opacity: 1, y: 0, } }">
      <h1 class="display-5 home-title">{{ t('home.title') }}</h1>
      <div class="home-introduction-subtitle">{{ t('home.subtitle') }}</div>
      <div class="row mt-5 cta-buttons">
        <div class="col-12 col-md-6 mb-2 text-md-end"><button @click="scrollTo('about')" class="btn btn-dark cta-button">{{ t('home.whoarewe') }}</button></div>
        <div class="col-12 col-md-6 mb-2 text-md-start"><button @click="scrollTo('contact')" class="btn btn-primary cta-button">{{ t('home.contact') }}</button></div>
      </div>
    </div>
  </section>

  <section class="about-us-section" id="about-section">
    <div class="about-part-1">
      <div class="row  ms-5 me-5">
        <div class="col-12 col-md-10 col-lg-10" v-motion="{ initial: { opacity: 0, y: 50 }, visible: { opacity: 1, y: 0, transition: { delay: 0, duration: 500 } } }"> 
          <div class="about-title"><h1 class="display-5 fw-bold">{{ t('about.title') }}</h1></div>
          <p class="about-subtitle" v-html="t('about.content')"></p></div>
      </div>
    </div>
    <div class="about-part-2 about-whychooseus">
      <div class="row ms-5 me-5">
        <div class="col about-title"><h1 class="display-5 fw-bold">{{ t('about.whychooseus') }}</h1></div>
      </div>
      <div class="row ms-5 me-5 about-content">
        <div 
          class="col-12 col-md" 
          v-motion="{ initial: { opacity: 0, y: 20 }, visible: { opacity: 1, y: 0, transition: { delay: 0, duration: 500 } } }">
          <PhGraduationCap class="about-icon"/>
          <h3>{{ t('about.reasons.a.title') }}</h3>
          <p v-html="t('about.reasons.a.text')"></p>
        </div>
        <div 
          class="col-12 col-md about-content" 
          v-motion="{ initial: { opacity: 0, y: 20 }, visible: { opacity: 1, y: 0, transition: { delay: 0.5 + 500 * 0.2, duration: 500 } } }">
          <PhUsersThree class="about-icon"/>
          <h3>{{ t('about.reasons.b.title') }}</h3>
          <p v-html="t('about.reasons.b.text')"></p>
        </div>
        <div 
          class="col-12 col-md about-content" 
          v-motion="{ initial: { opacity: 0, y: 20 }, visible: { opacity: 1, y: 0, transition: { delay: 0.5 + 1000 * 0.2, duration: 500 } } }">
          <PhHeadset class="about-icon"/>
          <h3>{{ t('about.reasons.c.title') }}</h3>
          <p v-html="t('about.reasons.c.text')"></p>
        </div>
        <div class="row">
          <div class="col-12 mt-5 mb-5 text-center"><button @click="scrollTo('contact')" class="btn btn-primary cta-button me-5">{{ t('home.contact') }}</button></div>
        </div>
      </div>
    </div>
  </section>

  <section class="contact-us-section" id="contact-section">
      <div class="row align-items-end p-5">
        <div class="col-12 col-md-6 p-3">
          <div class="text-white" v-motion-slide-visible-top>
            <h1 class="display-1 contact-title">{{ t('contact.title') }}</h1>
            <br><span class="contact-subtitle">{{ t('contact.subtitle') }}</span>
          </div>
        </div>
        <div class="col-12 col-md-6 contact-form" @submit.prevent="sendContactForm()" v-motion-slide-visible-bottom>
          <form v-if="!isContactSuccess">
            <h3 class="mb-4"><b>{{ t('contact.form.title') }}</b></h3>
            
            <div class="row mb-3">
              <div class="col">
                <label for="name" class="form-label fw-bold">{{ t('contact.form.name') }}</label>
                <input type="text" class="form-control" :class="invalidText['name'] ? 'is-invalid':''" id="name" aria-describedby="nameHelp" v-model="contact.name">
                <div class="invalid-feedback">{{ invalidText['name'] }}</div>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col">
                <label for="company" class="form-label fw-bold">{{ t('contact.form.company') }}</label>
                <input type="text" class="form-control" :class="invalidText['company'] ? 'is-invalid':''" id="company" aria-describedby="companyHelp" v-model="contact.company">
                <div class="invalid-feedback">{{ invalidText['company'] }}</div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-xl-6 mb-3">
                <label for="phone" class="form-label fw-bold">{{ t('contact.form.phone') }}</label>
                <input type="text" class="form-control" :class="invalidText['phone'] ? 'is-invalid':''" id="phone" aria-describedby="phoneHelp" v-model="contact.phone">
                <div class="invalid-feedback">{{ invalidText['phone'] }}</div>
              </div>
              <div class="col-12 col-xl-6 mb-3">
              <label for="email" class="form-label fw-bold">{{ t('contact.form.email') }}</label>
              <input type="email" class="form-control" :class="invalidText['email'] ? 'is-invalid':''" id="email" aria-describedby="emailHelp" v-model="contact.email">
              <div class="invalid-feedback">{{ invalidText['email'] }}</div>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col">
                <label for="message" class="form-label fw-bold">{{ t('contact.form.message') }}</label>
                <textarea class="form-control" :class="invalidText['message'] ? 'is-invalid':''" id="message" rows="3" v-model="contact.message"></textarea>
                <div class="invalid-feedback">{{ invalidText['message'] }}</div>
              </div>
            </div>
            <button type="submit" class="btn btn-primary cta-button">{{ t('contact.form.submit') }}</button>
          </form>
          <div class="contact-thanks d-flex align-items-center justify-content-center" v-else>
            <div>
              <h3 class="mb-4"><b>{{ t('contact.success.title') }}</b></h3>
              <p>{{ t('contact.success.text') }}</p>
            </div>
          </div>
        </div>
      </div>
  </section>

</template>

<script>
import { useI18n } from 'vue-i18n';
import axios from 'axios';
import { PhGraduationCap, PhUsersThree, PhHeadset } from "@phosphor-icons/vue";

export default {
  components: {
    PhGraduationCap,
    PhUsersThree,
    PhHeadset
  },
  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'global'
    })
    return { t }
  },
  data() {
    return {
      contact: {
        name: '',
        company: '',
        phone: '',
        email: '',
        message: ''
      },
      isContactSuccess: false,
      invalidText: {}
    }
  },
  methods: {
    scrollTo(section) {
      document.getElementById(section + "-section").scrollIntoView({ behavior: "smooth" });
    },
    sendContactForm() {
      // Send contact form
      this.invalidText = {};
      axios.post('/api/v1/contact/', {
        name: this.contact.name,
        company: this.contact.company,
        phone: this.contact.phone,
        email: this.contact.email,
        message: this.contact.message
      }).then(response => {
        if(response.status==201){
          this.isContactSuccess = true;
        }
        console.log('RESPONSE', response);
      }).catch(error => {
        if(error.status==400){
          for(let e in error.response.data){
            let errorText =  error.response.data[e];
            if(Array.isArray(errorText)){
              errorText = errorText[0].replace('.', '', 'g');
            }
            errorText = this.t('contact.errors.'+ errorText);

            this.invalidText[e] = errorText;
          }
        }
        else {
          console.log('ERROR', error);
        }
      });
    }
  },
};
</script>

<style scoped>

.home-title {
    font-weight: bold;
    text-shadow: 2px 2px 4px rgba(255, 255, 255, 0.7);
}


.about-us-section {
  min-height: 100vh;
  background-color: #f9f9f9;
  overflow: hidden;
}

.about-part-1 {
  min-height: 35vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 43px;
  background: url('../assets/img/aboutus_iconsback.png') no-repeat center center;

}

.about-part-2 {
  min-height: 65vh;
  display: flex;
  flex-direction: column;
  justify-content: center;

}

.about-title {
  color: #333;
  margin-bottom: 1.5rem;
  font-weight: bold;
}


.about-subtitle {
    font-size: 1.5rem;
    color: #555;
    margin-top: 1rem;
}

.about-content {
  font-size: 1.2rem;
  color: #555;
  line-height: 1.6;
}

.about-icon {
  font-size: 3rem;
  color: #555;
  margin-bottom: 0.5rem;
}

.about-whychooseus
{
  background-color: #e5ecff;
}

.about-whychooseus li {
  margin-bottom: 0.5rem;
}

.contact-us-section {
  min-height: 100vh;
  background-color: #f9f9f9;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 65px;
  background: url('../assets/img/cdilconsulting_contactus_back.jpg') no-repeat center center;
  background-size: cover;
}

.contact-us-section .row {
  width: 100%;
  height: 100%;
}

.contact-bg-black {
  background-color: rgba(0, 0, 0, 0.5);
  padding: 20px;
  border-radius: 5px;
}

.contact-title {
  color: #ffffff;
  margin-bottom: 1.5rem;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
}

.contact-subtitle {
  font-size: 1.5rem;
  color: #ffffff;
  margin-top: 1rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
}

.contact-form {
  background-color: rgba(255, 255, 255, 0.85);
  padding: 20px;
  border-radius: 5px;
}

.contact-thanks {
  min-height: 50vh;
}

.scroll-indicator {
  position: absolute;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
  width: 20px;
  height: 30px;
  border: 2px solid #fff;
  border-radius: 50px;
}

.scroll-indicator span {
  display: block;
  width: 8px;
  height: 8px;
  background: #fff;
  border-radius: 50%;
  margin: 5px auto;
  animation: scroll 1.5s infinite;
}

@keyframes scroll {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  50% {
    transform: translateY(10px);
    opacity: 0.5;
  }
  100% {
    transform: translateY(20px);
    opacity: 0;
  }
}

</style>