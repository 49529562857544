import { createStore } from 'vuex'
import axios from 'axios'

export default createStore({
  state: {
    user: {
      id: '',
      username: ''
    },
    isAuthenticated: false,
    token: '',
    refreshToken: '' // Add refreshToken to state
  },
  getters: {
  },
  mutations: {
    initializeStore(state)
    {
      if(localStorage.getItem('token'))
      { 
        state.token = localStorage.getItem('token'),
        state.refreshToken = localStorage.getItem('refreshToken'),
        state.isAuthenticated = true
        state.user.id = localStorage.getItem('userid'),
        state.user.username = localStorage.getItem('username')
      } 
      else
      {
        state.user.id = '',
        state.user.username = '',
        state.token = '',
        state.refreshToken = '',
        state.isAuthenticated = false
      }
    },
    setToken(state, token)
    {
      state.token = token,
      state.isAuthenticated = true
    },
    setRefreshToken(state, refreshToken)
    {
      state.refreshToken = refreshToken
    },
    removeToken(state)
    {
      state.user.id = '',
      state.user.username = '',
      state.token = '',
      state.refreshToken = '',
      state.isAuthenticated = false
    },
    setUser(state, user){
      state.user = user
    },
  },
  actions: {
    getUserInfos() {
      return axios.get('/api/v1/auth/users/me/')
        .then(response => {
          return response.data;
        })
        .catch(error => {
          console.error('User infos error:', error);
          throw error;
        });
    },
    login({ commit }, credentials) {
      return axios.post('/api/v1/auth/jwt/create/', credentials)
        .then(response => {
          const token = response.data.access;
          const refreshToken = response.data.refresh;
          localStorage.setItem('token', token);
          localStorage.setItem('refreshToken', refreshToken);
          commit('setToken', token);
          commit('setRefreshToken', refreshToken);
        })
        .catch(error => {
          //console.error('Login error:', error);
          throw error;
        });
    },
    refreshToken({ commit, state }) { // Add action to refresh token
      return axios.post('/api/v1/auth/jwt/refresh/', { refresh: state.refreshToken })
        .then(response => {
          const token = response.data.access;
          localStorage.setItem('token', token);
          commit('setToken', token);
        })
        .catch(error => {
          console.error('Token refresh error:', error);
          throw error;
        });
    }
  },
  modules: {
  }
})
