<template>
  <Header />
  <router-view/>
  <Footer />
</template>

<style lang="scss" src="./assets/scss/main.scss">
</style>

<script>
import axios from "axios";
import Header from "./components/Header";
import Footer from "./components/Footer";

export default {
  name: "App",
  components: {
    Header,
    Footer
  },
  beforeCreate() {
    this.$store.commit("initializeStore");
    const token = this.$store.state.token;

    if (token) {
      axios.defaults.headers.common["Authorization"] = "Token " + token;
    } else {
      axios.defaults.headers.common["Authorization"] = "";
    }
  }
};
</script>