<template>
    <footer>
        <nav class="navbar fixed-bottom footer-cc">
            <div class="container-fluid small" v-html="copyrightText"></div>
        </nav>
    </footer>
</template>

<script>

import { useI18n } from 'vue-i18n';

export default {
    name: "Footer",
    setup() {
        const { t } = useI18n({
            inheritLocale: true,
            useScope: 'global'
        })
        return { t }
    },
    computed: {
        copyrightText(){
        const currentYear = new Date().getFullYear();
        return " &copy; " + currentYear + " " + this.t('footer.allrights');
        },
    },
};
</script>