<template>
    <header>
        <nav class="navbar navbar-expand-lg navbar-dark navbar-cc fixed-top">
            <div class="container-fluid">
                <router-link to="/" class="navbar-brand">
                    <img src="../assets/img/cdilconsulting_navbar.jpg" alt="logo" height="30" class="d-inline-block align-text-top me-2">
                    {{ t('header.brand') }}
                </router-link>
                <router-link to="/" class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </router-link>
                <div class="collapse navbar-collapse" id="navbarCollapse">
                    <ul class="navbar-nav me-auto mb-2 mb-md-0">
                        <li class="nav-item me-3"><router-link to="#home-section" class="nav-link">{{ t('menu.home') }}</router-link></li>
                        <li class="nav-item me-3"><router-link to="#about-section" class="nav-link">{{ t('menu.about') }}</router-link></li>
                        <li class="nav-item me-3"><router-link to="#contact-section" class="nav-link">{{ t('menu.contact') }}</router-link></li>
                    </ul>
                    <div class="loginFields" v-if="showLogin">
                        <form @submit.prevent="login()">
                            <div class="row">
                                <div class="col-12 col-md">
                                    <input type="text" class="form-control" :class="loginErrors ? 'is-invalid':''" :placeholder="t('login.email')" v-model="$store.state.user.username">
                                </div>
                                <div class="col-12 col-md">
                                    <input type="password" class="form-control" :class="loginErrors ? 'is-invalid':''"  :placeholder="t('login.password')" v-model="$store.state.user.password">
                                </div>
                                <div class="col-auto">
                                    <button type="submit" class="btn btn-outline-light">{{ t('login.submit') }}</button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="navbar-nav">
                        <button @click="showLoginFields()" class="btn btn-outline-light ms-4" v-if="!showLogin">{{ t('login.title') }}</button>
                        <li class="nav-item dropdown">
                            <button class="nav-link ms-2 dropdown-toggle"  role="button" data-bs-toggle="dropdown" aria-expanded="false"  ><img :src="getFlag($i18n.locale)" alt="flag" width="20" class="me-2"></button>
                            <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="languagesDropdown">
                                <li v-for="(lng, i) in Object.keys(languages)" :key="`Lang${i}`" :value="lng">
                                    <a class="dropdown-item" @click="changeLanguage(lng)" href="#"><img :src="getFlag(lng)" alt="flag" width="20" class="me-2"> {{ languages[lng].nativeName }}</a>
                                </li>
                            </ul>
                        </li>
                    </div>
                </div>
            </div>
        </nav>
    </header>
</template>

<script>

import { useI18n } from 'vue-i18n';

export default {
    name: "Header",
  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'global'
    })
    return { t }
  },
  data() {
    return {
      showLogin: false,
      loginErrors: null,
      languages: {
        en: { nativeName: 'English' },
        fr: { nativeName: 'Français' },
        es: { nativeName: 'Español' }
      }
    }
  },
methods: {
    changeLanguage(lang) {
        this.$i18n.locale = lang;
    },
    getFlag(lang) {
        return require(`../assets/img/flags/${lang}.svg`);
    },
    login() {
        this.loginErrors = null;
        this.$store.dispatch("login", {
            username: this.$store.state.user.username,
            password: this.$store.state.user.password
        }).then(() => {
            //this.$router.push({ name: "Home" });
        }).catch((error) => {
            if(error.response && error.response.data)
            {
                this.loginErrors = error.response.data;
            }
        });
    },
    showLoginFields() {
        this.showLogin = !this.showLogin;
    }
}
};
</script>